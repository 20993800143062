.content {
    max-width: 1040px;
    margin: 0 auto;

}

.container {
    margin-top: 90px;
}

.container h2 {
    font-family: 'Play', sans-serif;
    font-weight: 400px;
    line-height: 74px;
    font-style: normal;
    font-size: 64px;
    text-align: center;
}

.opacityContainer{
    background: rgba(255, 255, 255, 0.6);
}

.grid {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}


.services h2 {
    text-align: center;
}

.service {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-top: 40px;
    margin-right: 30px;
    font-family: "Play";
    font-size: 30px;
    width: 320px;
    height: 340px;
    border: 2px solid #000000;
    border-radius: 10px;
}

.service p {
    margin-top: 40px;
}

.servicesList {
    justify-content: center;
}

.servicesList:last-child {
    margin-right: 0;
}

.marginFix {
    margin-right: 0;
}

.polishing {
    margin-top: 30px;
}

.polishing p {
    margin-top: 10px;
}

.serviceText {
    max-width: 270px;
}
