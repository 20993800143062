.content {
    max-width: 1040px;
    margin: 0 auto;

}

.container {
    margin-top: 90px;
}

.container h2 {
    font-family: 'Play', sans-serif;
    font-weight: 400px;
    line-height: 74px;
    font-style: normal;
    font-size: 64px;
    text-align: center;
}

.container .text {
    margin-top: 30px;
    max-width: 505px;
    font-family: "Vollkorn";
    font-size: 16px;
    line-height: 18px;
}

.opacityContainer{
    background: rgba(255, 255, 255, 0.6);
}

.grid {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.aboutService h2{
    text-align: unset;
}

.aboutServiceImg {
    max-width: 483px;
    max-height: 322px;
}

.aboutService .text {
    margin-right: 52px;
}
