.content {
    max-width: 1040px;
    margin: 0 auto;

}

.container {
    margin-top: 90px;
}

.container h2 {
    font-family: 'Play', sans-serif;
    font-weight: 400px;
    line-height: 74px;
    font-style: normal;
    font-size: 64px;
    text-align: center;
}

.opacityContainer{
    background: rgba(255, 255, 255, 0.6);
}

.grid {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}


.reviews h3{
    font-family: 'Vollkorn';
    font-style: normal;
    font-size: 48px;
    text-align: center;
    line-height: 18px;
    margin-top: 20px;
}
.review {
    width: 296px;
    height: 351px;
    border: 2px solid #000000;
    border-radius: 10px;
    margin-right: 30px;
}

.reviewsList{
    margin-top: 50px;
    justify-content: center;
}

.lastReview {
    margin-right: 0;
}

.review h4 {
    font-family: 'Vollkorn';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 18px;
    text-align: center;
    margin-top: 30px;
}

.textReview {
    margin-top: 25px;
    font-family: 'Vollkorn';
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    text-align: center;
    padding: 0 10px;
}

.bigSize {
    margin-top: 10px;
    font-size: 20px;
}

.image {
    margin-top: 13px;
    text-align: center;
}

