.content {
    max-width: 1040px;
    margin: 0 auto;

}
.bar {
    padding: 0;
    background: #24292F;
}

header {
    font-family: "Vollkorn";
    font-size: 20px;
    font-weight: 400px;
    color: #FFFFFF;
    display: flex;
    justify-content: space-between;
}

header div, header nav {
    margin-top: 30px;
    margin-bottom: 30px;
}

.grid {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}


nav ul{
    justify-content: space-between;
    width: 380px;
}

.navItem {

}

.navItem:last-child{

}

.navItem a {
    color: #FFFFFF;
}

.logo {

}

.phoneNumber img {
    margin-right: 15px;
}
