.content {
    max-width: 1040px;
    margin: 0 auto;

}

.container {
    margin-top: 90px;
}

.container h2 {
    font-family: 'Play', sans-serif;
    font-weight: 400px;
    line-height: 74px;
    font-style: normal;
    font-size: 64px;
    text-align: center;
}

.opacityContainer{
    background: rgba(255, 255, 255, 0.6);
}

.grid {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.guarantees .grid img {
    margin-right: 116px;
}

.guaranteesList {
    margin-top: 40px;
    font-size: 20px;
    font-family: "Vollkorn";
    font-size: 20px;
}

.guaranteesList .grid img {
    margin-right: 20px;
}

.guaranteesList .grid {
    margin-top: 30px;
}

.guaranteesList:first-child {
    margin-top: 0;
}
