.content {
    max-width: 1040px;
    margin: 0 auto;

}

.container {
    margin-top: 90px;
}

.bar {
    padding: 0;
    background: #24292F;
}

.grid {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}


.map{
    margin: 45px 0
}
.listContacts{
    font-family: 'Vollkorn';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    color: #F8F8F8;
}
.listContacts li{
    margin-top: 30px;
}

.listContacts li:first-child{
    margin-top: 0;
}

.listContacts img{
    margin-right: 15px;
}
.contact{
    font-family: 'Play';
    font-style: normal;
    font-weight: 400;
    font-size: 40px;
    color: #F8F8F8;
}

.footer {

}

footer {
    justify-content: space-between;
}

.opacityContainer{
    background: rgba(255, 255, 255, 0.6);
}
